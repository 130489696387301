<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">

      <!-- Brand logo-->
      <b-link class="w-100 col-lg-8 d-lg-flex brand-logo">
        <vuexy-logo />
        <!-- <h2 class="brand-text text-primary ml-1">
          Selamat Datang
        </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col
        lg="8"
        class="d-none d-lg-flex align-items-center p-5"
      >
        <div class="w-100 d-lg-flex align-items-center justify-content-center px-5">
          <b-img
            fluid
            :src="imgUrl"
            alt="Login V2"
          />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col
        lg="4"
        class="d-flex align-items-center auth-bg px-2 p-lg-5"
      >
        <b-row class="mt-5 mt-lg-0">
          <b-col
            sm="8"
            md="6"
            lg="12"
            class="px-xl-2 mx-auto pt-5 pt-lg-0"
          >
            <b-card-title class="mb-1 pt-xs-100">
              Portal Login SI PLM
            </b-card-title>
            <b-card-text class="mb-2">
              Di sini, anda dapat melihat Penanganan Laporan Masyarakat 
            </b-card-text>

            <!-- form -->
            <validation-observer
              ref="loginForm"
              #default="{invalid}"
            >
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent="login"
              >
                <!-- username -->
                <b-form-group
                  label="Email"
                  label-for="login-username"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Email"
                    vid="username"
                    rules="required|email"
                  >
                    <b-form-input
                      id="login-username"
                      v-model="userName"
                      :state="errors.length > 0 ? false:null"
                      name="login-username"
                      placeholder="Email"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- Lupa Password -->
                <b-form-group>
                  <div class="d-flex justify-content-between">
                    <label for="login-password">Password</label>
                    <b-link :to="{name:'auth-forgot-password'}">
                      <small>Lupa Password?</small>
                    </b-link>
                  </div>
                  <validation-provider
                    #default="{ errors }"
                    name="Password"
                    vid="password"
                    rules="required"
                  >
                    <b-input-group
                      class="input-group-merge"
                      :class="errors.length > 0 ? 'is-invalid':null"
                    >
                      <b-form-input
                        id="login-password"
                        v-model="password"
                        :state="errors.length > 0 ? false:null"
                        class="form-control-merge"
                        :type="passwordFieldType"
                        name="login-password"
                        placeholder="Password"
                      />
                      <b-input-group-append is-text>
                        <feather-icon
                          class="cursor-pointer"
                          :icon="passwordToggleIcon"
                          @click="togglePasswordVisibility"
                        />
                      </b-input-group-append>
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- checkbox -->
                <b-form-group>
                  <b-form-checkbox
                    id="remember-me"
                    v-model="status"
                    name="checkbox-1"
                  >
                    Ingat Saya
                  </b-form-checkbox>
                </b-form-group>

                <!-- submit buttons -->
                <b-button
                  type="submit"
                  variant="primary"
                  block
                  :disabled="invalid"
                >
                  Sign in
                </b-button>
              </b-form>
            </validation-observer>

            <b-card-text class="text-center mt-2">
              <span>Belum memiliki Akun? </span>
              <b-link :to="{name:'auth-register-token'}" v-if="this.applicationToken">
                <span>&nbsp;Daftar Sekarang</span>
              </b-link>
              <b-link :to="{name:'auth-register'}" v-else>
                <span>&nbsp;Daftar Sekarang</span>
              </b-link>
            </b-card-text>
          </b-col>
        </b-row>
      </b-col>
    <!-- /Login-->
    </b-row>
  </div>
</template>

<script>
import {
  Plugins,
  PushNotification,
  PushNotificationToken,
  PushNotificationActionPerformed,
} from '@capacitor/core'
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@/views/layouts/components/LogoDouble.vue'
import {
  BRow, BCol, BLink, BCardTitle, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BImg, BForm, BButton, BAlert, VBTooltip,
} from 'bootstrap-vue'
// import useJwt from '@/auth/jwt/useJwt'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import router from '@/router'
import { getHomeRouteForLoggedInUser } from '@/auth/utils'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import {
  Authlogin,
  setToken,
  setRefreshToken,
  verifToken,
} from '@/connection-api/authentication'

const { PushNotifications } = Plugins

export default {
  directives: {
    'b-tooltip': VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BImg,
    BForm,
    BButton,
    BCardTitle,
    // BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userName: '',
      sideImg: require('@/assets/images/pages/login-v2.png'),

      // validation rules
      required,
      email,
      applicationToken: null,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  async mounted() {

    if(this.$route.params.token){
          const data = {token: this.$route.params.token}
          const response = await verifToken(data)
      
          if (response.data.success == true){
            alert(response.data.message)
          }else{
            alert(response.data.message)
          }
    }


    this.applicationToken = router.currentRoute.params.token
    const tokenFirebase = localStorage.getItem('tokenFirebase')
    if (tokenFirebase === null) {
      // PushNotifications.register()

      // PushNotifications.addListener('registration', token => {
      //   localStorage.setItem('tokenFirebase', token.value)
      //   // alert(`Push registration success, token: ${token.value}`)
      // })

      // PushNotifications.addListener('registrationError', error => {
      //   // alert(`Error on registration: ${JSON.stringify(error)}`)
      // })

      // PushNotifications.addListener('pushNotificationReceived', notification => {
      //   // alert(`Push received: ${JSON.stringify(notification)}`)
      // })

      // PushNotifications.addListener('pushNotificationActionPerformed', notification => {
      //   // alert(`Push action performed: ${JSON.stringify(notification)}`)
      // })
    }
    
  },
  methods: {
    login() {
      this.$refs.loginForm.validate().then(success => {
        if (success) {
          Authlogin({
            email: this.userName,
            password: this.password,
            application_token: this.applicationToken,
            query: router.currentRoute.query,
            token_firebase: localStorage.getItem('tokenFirebase'),
          })
            .then(response => {
              if (response.data.error) {
                this.$swal({
                  title: 'Gagal!',
                  html: `${response.data.message}`,
                  icon: 'error',
                  customClass: {
                    confirmButton: 'btn btn-primary',
                  },
                  buttonsStyling: false,
                })
               
              } else {
                const { member, urlCallback } = response.data.data
                setToken(response.data.data.accessToken)
                setRefreshToken(response.data.data.refreshToken)

                /// /////// TEMPORARY ACL SIMULATION ////////////////

                // baca value userData.roles
                // value-nya mungkin bisa  array of string atau hanya string
                // jadi perlu check if array
                let userRole = ''
                if (Array.isArray(member.roles)) {
                  // ambil item pertama
                  const test = member.roles[0]
                  userRole = test
                } else {
                  userRole = member.roles
                }

                localStorage.setItem('userData', JSON.stringify(member))
                this.$ability.update(member.ability)
                if (urlCallback === '') {
                  this.$router.push('/dashboard/analytics')
                    .then(() => {
                      this.$toast({
                        component: ToastificationContent,
                        position: 'top-right',
                        props: {
                          title: `Selamat Datang ${member.fullName || member.username}`,
                          icon: 'CoffeeIcon',
                          variant: 'success',
                          text: 'Anda telah berhasil masuk SI PLM. Sekarang Anda bisa mulai menjelajah!',
                        },
                      })
                    })
                } else {
                  localStorage.setItem('urlCallback', urlCallback)
                  window.location.href = urlCallback
                }
              }
            })
        }
      })
    },
    getParameterFromURL() {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      return token;
    },

  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
.grecaptcha-badge { visibility: hidden !important; }
</style>
